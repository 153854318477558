Beacon.LazyLoader = {

    trackedImages: null,
    debounceTimer: -1,
    settingsTag: 'ShowPhotos',
    offscreenPreloadBuffer: 200,
    iconUrl: '/Icons/Report/Report-16-Enabled.gif',
    blankUrl: '/Images/white_pixel.png',
    missingUrl: '/PhotoEngine/blank_house.png',

    // at pageload:
    initialize: function () {
        // remove the superfluous label
        var chkShowPhotos = $('.showImageCheckBox');
        let chkShowPhotosID = chkShowPhotos.attr('id');
        if (chkShowPhotosID && chkShowPhotos !== '') {
            $('label[for="' + chkShowPhotosID + '"]').remove();
        }

        $('.showImageCheckBox').replaceWith('<button aria-live="polite" class="btn btn-primary showImageCheckBox" type="button">Click to Show Property Photos</button>')
        $('.showImageCheckBox').parent('label').replaceWith('<button aria-live="polite" class="btn btn-primary showImageCheckBox" type="button">Click to Show Property Photos</button>');
        chkShowPhotos = $('.showImageCheckBox');
        if (chkShowPhotos.length > 0) {
            chkShowPhotos.click(Beacon.LazyLoader.checkBoxHandler);

            var setting = Beacon.localStorage[Beacon.LazyLoader.settingsTag] === 'true';

            if (setting) {
                chkShowPhotos.attr('checked', 'YES');
                Beacon.LazyLoader.enableLazyImages();
                Beacon.GA.TrackEvent('ShowPhotos', 'On');
                $(chkShowPhotos).text('Click to Hide Property Photos');
            } else {
                Beacon.LazyLoader.disableLazyImages();
                Beacon.GA.TrackEvent('ShowPhotos', 'Off');
            }
        }
    },

    // from UI checkbox
    checkBoxHandler: function (event) {
        if (!$(event.target).hasClass('showImageCheckBox')) {
            return;
        }
        
        event.preventDefault();
        event.stopPropagation();

        if (Beacon.localStorage[Beacon.LazyLoader.settingsTag] === 'false') {
            Beacon.LazyLoader.enableLazyImages();
            Beacon.localStorage[Beacon.LazyLoader.settingsTag] = 'true';
            Beacon.GA.TrackEvent('ShowPhotos', 'Turned On');
            $(event.target).text('Click to Hide Property Photos');
        } else {
            Beacon.LazyLoader.disableLazyImages();
            Beacon.localStorage[Beacon.LazyLoader.settingsTag] = 'false';
            Beacon.GA.TrackEvent('ShowPhotos', 'Turned Off');
            $(event.target).text('Click to Show Property Photos');
        }
    },


    // internal -------------------------------------

    enableLazyImages: function () {

        if (Beacon.LazyLoader.trackedImages != null) return; //already inited
        Beacon.LazyLoader.trackedImages = [];

        $('img[LAZYURL],input:image[LAZYURL]').each(function () {

            Beacon.LazyLoader.trackedImages.push({ image: $(this), loaded: false });

            //add blank image
            $(this)
                .attr('src', Beacon.LazyLoader.blankUrl)
                .height(48)
                .width(48);

            $(this).parent().width("56px");

        });

        $(window).on("scroll resize", Beacon.LazyLoader.onScrollAndResize);

        Beacon.LazyLoader.onScrollAndResize();

    },

    disableLazyImages: function () {

        if (Beacon.LazyLoader.trackedImages == null) return; //already killed

        if (Beacon.LazyLoader.debounceTimer != -1) clearTimeout(Beacon.LazyLoader.debounceTimer);
        Beacon.LazyLoader.debounceTimer = -1;

        $(window).unbind('scroll resize', Beacon.LazyLoader.onScrollAndResize);

        for (var i = 0; i < Beacon.LazyLoader.trackedImages.length; i++) {
            var img = Beacon.LazyLoader.trackedImages[i];
            img.image.attr('src', Beacon.LazyLoader.iconUrl);
            img.image.height(16);
            img.image.width(16);
            $(img.image).parent().width("16px");
        }

        Beacon.LazyLoader.trackedImages = null;

    },

    fixupAfterGridSort: function () {
        if (!Beacon.LazyLoader.trackedImages) return;

        Beacon.LazyLoader.trackedImages.sort(function (a, b) {
            return a.image.offset().top - b.image.offset().top;
        });

        $(window).scroll();
    },


    onScrollAndResize: function () {

        if (Beacon.LazyLoader.debounceTimer != -1) {
            clearTimeout(Beacon.LazyLoader.debounceTimer);
            Beacon.LazyLoader.debounceTimer = -1;
        }

        Beacon.LazyLoader.debounceTimer = setTimeout(Beacon.LazyLoader.loadVisibleImages, 100);

    },

    loadVisibleImages: function () {

        var screenTop = $(window).scrollTop();
        var screenBottom = screenTop + $(window).height() + Beacon.LazyLoader.offscreenPreloadBuffer;

        for (var i = 0; i < Beacon.LazyLoader.trackedImages.length; i++) {

            var img = Beacon.LazyLoader.trackedImages[i];

            if (!img.loaded) {

                var imgTop = img.image.offset().top;
                var imgBot = imgTop + img.image.height();

                //short circuit if past bottom of screen or div: -- removed 5/10/13 jdp: dynamic sorting prevents this optimization from working
                if (imgTop > screenBottom) break;

                var visInScrn = (imgBot >= screenTop) && (imgTop <= screenBottom);

                //now, if visible, load
                if (visInScrn) {
                    img.image.on("error", Beacon.LazyLoader.imageLoadError);
                    img.image.attr('src', img.image.attr('LAZYURL'));
                    img.loaded = true;
                }

            }
        }
    },

    // this supports azure-based images, since we can't 
    imageLoadError: function (e) {        
        return Beacon.LazyLoader.swapImageOnFailure(e.target);
        //e.target.onerror = null;
        //e.target.src = Beacon.LazyLoader.missingUrl;
        //return null;
    },

    // this supports azure-based images for map tips and other one-off instances that dynamically load 
    hideImageOnFailure: function (el) {
        $(el).off("error").hide();
        return null;
    },

    swapImagesOnError: function (urls, el) {
        var src = $(el).attr('src');

        var splitUrls = urls ? urls.split(';') : [];
        var cnt = splitUrls.length - 1;

        var index = splitUrls.indexOf(src);

        if (index == cnt) {
            //The last image failed, remove the error handler and set the src to the missing image url
            $(el).off("error").attr("src", Beacon.LazyLoader.missingUrl);
        }
        else {
            //Set the src to the next image in the list
            $(el).attr("src", splitUrls[index + 1]);
        }

        return null;
    },

    swapCarouselImageOnError: function (el) {
        var urls = $(el).attr('data-primaryPhotoUrls');    
        return Beacon.LazyLoader.swapImagesOnError(urls, el)
    },

    // this supports azure-based images for map tips and other one-off instances that dynamically load 
    swapImageOnFailure: function (el) {
        var urls = $(el).attr('photoUrls');

        return Beacon.LazyLoader.swapImagesOnError(urls, el)
    }
};
Beacon.WCAG = {

    initialize: function () {
        //add spacebar support for ExpandCollapse elements
        $(".expandCollapseIcon").each(function () {
            Beacon.WCAG.addSpacebarSupport($(this));
            //add aria-expanded label support for expandCollapseIcon
            if ($(this).hasClass("icon-plus-squared-alt")) {
                $(this).attr('aria-expanded', 'false');
            }
            $(this).click(function () {
                if ($(this).hasClass("icon-plus-squared-alt")) {
                    $(this).attr('aria-expanded', 'true');
                }
                else {
                    $(this).attr('aria-expanded', 'false');
                }
            });
        });
        // end add spacebar support 


        this.addPauseAnimation();
        this.addButtonPolyfill();
        this.fixTables_Presentation();
        this.fixTables_Data();
        this.HeadingsandLabels2_4_6.updateRowHeaders('.tabular-data-two-column td:nth-child(1)');
        this.HeadingsandLabels2_4_6.updateRowHeaders('.salesSearchTable td:nth-child(1)');
        this.HeadingsandLabels2_4_6.updateRowHeaders('.compSearchTable td:nth-child(2)');
        this.HeadingsandLabels2_4_6.updateColHeaders('.compSearchTable');
     //   this.HeadingsandLabels2_4_6.updateColHeaders('.compSearchTableOne');
     //   this.HeadingsandLabels2_4_6.updateRowHeaders('.compSearchTableOne td:nth-child(2)');
     //   this.HeadingsandLabels2_4_6.updateColHeaders('.compSearchTableTwo');
     //   this.HeadingsandLabels2_4_6.updateRowHeaders('.compSearchTableTwo td:nth-child(2)');
     //   this.HeadingsandLabels2_4_6.updateColHeaders('.compSearchTableThree');
     //   this.HeadingsandLabels2_4_6.updateRowHeaders('.compSearchTableThree td:nth-child(2)');
        this.HeadingsandLabels2_4_6.updateColHeaders('.salesSearchTable');

        this.associateHelpBlocks();
    },
/**
* Wires up a link button to expand or collapse a list of div's with one button click.
* @param {string[]} elementIds array with the div id's we are toggling on or off
* @param {string} text sometimes we pass in additional text to toggle.
* @param {string} buttonID (optional) The ID of the control element, that is, the button that toggles expand/collapse
*/
    ExpandCollapse: function (elementIds,text,buttonID) {
        var elementId = "";
        //sometimes some rouge developer might call this function by passing in a single string elementId rather than an array.
        if (Array.isArray(elementIds)) {
            elementId = elementIds.shift();
            while (elementIds.length >= 1) {   
                    Beacon.WCAG.ExpandCollapse(elementIds,text);
                }
        } else {
            //not array, just a single string value;
            elementId = elementIds;
        }
        var div = document.getElementById(elementId);
        if (!buttonID) {
            buttonID = 'btn' + elementId;
        }
        var b = $('#' + buttonID);
        if (div.style.display === "none") {
            div.style.display = "inline";
            b.toggleClass("icon-minus-squared-alt").toggleClass("icon-plus-squared-alt");
            //I don't know why I would ever want to pass in an different text value, but that's the way some of them were ~20200122 jm
            b.text(text?"Hide " + text:b.text().replace("Show","Hide"));
            }
        else {
            b.toggleClass("icon-plus-squared-alt").toggleClass("icon-minus-squared-alt");
            div.style.display = "none";
            b.text(text ? "Show " + text : b.text().replace("Hide", "Show"));
            }
    },

    addSpacebarSupport: function ($btn) {
        $btn.keydown(function (e) {
            if (e.keyCode === Beacon.WCAG.keyCode.SPACE) {
                e.preventDefault();
                e.target.click();
            }
        });
    },
    animationStatus: 1
     ,

    keyCode: {
        RETURN: 13,
        SPACE: 32,
        PAGEUP: 33,
        PAGEDOWN: 34,
        END: 35,
        HOME: 36,
        LEFT: 37,
        UP: 38,
        RIGHT: 39,
        DOWN: 40,
        SLASH: 191,
        ESCAPE: 27
    },
    addPauseAnimation: function () {
        var pauseAnimation = function () {

            Beacon.WCAG.animationStatus = 1 - Beacon.WCAG.animationStatus;
         
            if (Beacon.WCAG.animationStatus === 0) {
                $(".load-bar").children().each(function () {
                    w = ($(this).width());
                    l = ($(this).css("left"));
                    $(this).css("width", w);
                    $(this).css("left", l);
                    $(this).css("animation", 'none');
                });
            }
            else {
                $(".load-bar").children().each(function () {
                    ($(this).removeAttr("style"));
                });
            }
        };
        if (Beacon.WCAG.animationStatus == 0) {
            Beacon.WCAG.animationStatus = 1;
            pauseAnimation();

        }

        $(document).keydown(function (e) {
            if (event.keyCode === Beacon.WCAG.keyCode.ESCAPE) {
                e.preventDefault();
                pauseAnimation();
            }
        });
        
       },
    addButtonPolyfill: function () {
        // POLYFILL - allow spacebar to activate links that look like buttons
        //          - it won't mess with links that already have a role defined

        $("a.btn").not("[role]").keydown(function (e) {
            if (e.keyCode === Beacon.WCAG.keyCode.SPACE) {
                e.preventDefault();
                e.target.click();
            }
        }).attr('role', 'button');
    },

    fixTables_Presentation: function () {

        // two-col tables - presentation
        $("table.tabular-data-two-column").not("[role]").attr("role", "presentation");

        // tables with only one row and no thead (assume its just for display)
        $("table").not("[role]").has("tbody:only-child > tr:only-child").attr("role", "presentation");

        // tables with only one column and no thead
        $("table").not("[role]").has("tbody:only-child > tr:first > td:only-child").attr("role", "presentation");

    },

    HeadingsandLabels2_4_6:
    {
        //remove the presentation role
        
        updateColHeaders: function (tableClass) {
            
            $(tableClass).attr('role', 'table');
            if (($(tableClass).has('thead')).length === 0) {
                $(tableClass + ' tr:first-child').children('td,th').replaceWith(function (i, html) {
                    if (html === '&nbsp;') {
                        return '<td scope="row">&nbsp;</td>';
                    } else if ($(this).text().trim() === '') {
                        return '<td scope="row"></td>';
                    } else {
                        return '<th scope="row">' + html + '</th>';
                    }
                });
                var headerRow = $(tableClass + ' tr:first-child');
                var tc = $(tableClass);
                headerRow.each(function (i, element) {
                    $(tc[i]).prepend($('<thead>').append(headerRow[i]));
                });
              
            }
        },
        updateRowHeaders: function (className) {
        

            $(className).replaceWith(function (i, html) {
                if (html === '&nbsp;') {
                    return '<td scope="row">&nbsp;</td>';
                } else if ($(this).text().trim() === '') {
                    return '<td scope="row"></td>';
                } else {
                    return '<th scope="row">' + html + '</th>';
                }
            });

        }
    },

    fixTables_Data: function () {

        // tables w/o caption
        var dataTables = $("table[role!='presentation']").not(":has(caption)");
        dataTables.each(function () {
            var moduleTitle = $(this).parents("section").find("div.title").text();
            if (moduleTitle) {
                var caption = $("<caption></caption>");
                caption.text(moduleTitle).addClass("sr-only");
                $(this).append(caption);
            }
        });

    },

    visualLintRules: function () {

        // table rows without th[scope=row] in the data row
        $("table[role!=presentation] > tbody > tr").not(":has(th[scope=row])").each(function (index) {
            // figure out which cell is 'supposed' to be the header of the row
            var rowHeaderIndex = $(this).parents('table').first().attr('rowheaderindex');
            if (rowHeaderIndex === undefined) {
                rowHeaderIndex = 0;
            }

            // if that cell is blank, then it should not be a row header
            if ($($(this).children[rowHeaderIndex]).text().trim() !== '') {
                $(this).css('border', '2px solid red').attr("title", "WCAG ERROR: table rows don't have a <th scope=row>, or table needs to set role=presentation");
            }
        });

        // WCAG - nested tables flagged
        $("table[role!='presentation'] table[role!='presentation']").css('border', '2px solid red').attr("title", "WCAG ERROR: nested tables - if outer table is for layout set role=presentation");

    },

    associateHelpBlocks: function () {
        $('section').each(function (idx) {
            /* The majority of cases are simple: 1 button in the whole module, which is a search button,
             * and one .help-block element, which is not associated with anything. In those cases, feel
             * free to associate them.
             * 
             * Conditions:
             * - 1 .help-block element in the whole module
             * - That help-block element contains text
             * - 1 button (or input[type="button"]) in the whole module
             * - That button has a search glyphicon
             * - That button does not have an aria-labelledby attribute
             */

            const helpBlock = $(this).find('.help-block');
            if (helpBlock.length !== 1) {
                return;
            }
            if ($(helpBlock).text().trim() === '') {
                return;
            }

            const searchButton = $(this).find('.btn.btn-primary');
            if (searchButton.length !== 1) {
                return;
            }
            if ($(searchButton).find('.glyphicon.glyphicon-search').length < 1) {
                return;
            }
            if ($(searchButton).attr('aria-describedby') !== undefined) {
                return;
            }

            $(searchButton).attr('aria-describedby', $(helpBlock).attr('id'));
        });
    },

    CLASS_NAME: "Beacon.WCAG"
};




Beacon.SearchBox = {

    initialize: function () {

        Beacon.SearchBox.initializeGlobalSearchBox();
        Beacon.SearchBox.initializeNameSearchBox();
        Beacon.SearchBox.initializeAddressSearchBox();
        Beacon.SearchBox.initializeStreetNameSearchBox();
        Beacon.SearchBox.initializeParcelIdSearchBox();
        Beacon.SearchBox.initializeAlternateIdSearchBox();
        Beacon.SearchBox.initializeAlternateIdExactMatchSearchBox(); 
        Beacon.SearchBox.initializeNeighborhoodSearchBox();
        Beacon.SearchBox.initializeBookSearchBox();
        Beacon.SearchBox.initializePageSearchBox();
        Beacon.SearchBox.initializeDBASearchBox();
        Beacon.SearchBox.initSearchIntentHandler();
    },

    /**
 * adds the module header to the labelledby of the elementID inserted.
 * it works it way up the DOM until it gets to the section and then works it's way back down to the title.
 * @param {string} elementId the id of the element (usually a button) to add the aria-labelledby attribute to.
 */
    initializeModuleHeaderToLabelTheInput : function (elementId) {
    
        $(elementId).attr('aria-labelledby', $(elementId).parentsUntil('section').last().parent().find('.title').first().attr('id'));
    },

/**
* adds the module header to the labelledby of the ButtonID inserted.
* it works it way up the DOM until it gets to the section and then works it's way back down to the title.
*adds the fieldset to the entire module that contains the button that needs labeled.
* @param {string} fieldName the id of the element (usually a button) to add the aria-labelledby attribute to.
*/
    initializeSearchButtonToFieldsets: function (fieldName) {
        
            //to function correctly the buttons need to be both lablelled by AND inside of a fieldset!
            $(fieldName).parent().each(function (btn_idx, btn) {
                $(btn).attr('aria-labelledby', $(btn).parentsUntil('section').last().parent().find('.title').first().attr('id'));
                var moduleElement = $(btn).parentsUntil('section').last().parent().find('.module-content');
                var item = moduleElement.has('fieldset');
                if (item.length == 0) {
                    moduleChildren = moduleElement.children();
                    moduleElement.append($('<fieldset />').append($('<legend aria-labelledby=' + $(btn).attr('aria-labelledby') + ' />')).append(moduleChildren));
                }
            });

    },

    initializeGlobalSearchBox: function () {

        var ctrl = $(".tt-upm-global-search");

        //ctrl.focus(function () { $("#topSearchControl").animate({ width: 150 }, 200); })
        //    .blur(function () { $("#topSearchControl").animate({ width: 90 }, 200); });
        ctrl.keypress(function (event) {
            if (event.which === 13) {
             
                //alert("search happens now for: " + $(this).val());
                //This is when the default layer needs to be made active
              //  console.log(mapConfig);
           
                event.preventDefault();
            }
               
           
        });

        Handlebars.registerHelper('appId', function (object) {
            return mapConfig.AppId;
        });

        var searchArgs = [{
            minLength: 3,
            highlight: true,
            hint: false
        }];

        if (mapConfig.Search.Name) {
            searchArgs.push({
                name: 'owner-names',
                source: Beacon.SearchBox.createBloodhoundAdapter("name", 5, "OwnerName", true),
                displayKey: 'OwnerName',
                templates: {
                    header: Beacon.Templates.searchbox_Owners_header,
                    suggestion: Beacon.Templates.searchbox_Owners,
                    empty: "none"
                }
            });
        }

        if (mapConfig.Search.Address) {
            searchArgs.push({
                name: 'prop-addresses',
                //source: this.propAddressSource,
                source: Beacon.SearchBox.createBloodhoundAdapter("address", 5, "Address", true),
                displayKey: 'Address',
                templates: {
                    header: '<div class="tt-category">Property Addresses</div>',
                    suggestion: Beacon.Templates.searchbox_Addresses,
                    empty: "none"
                }
            });
        }

        if (mapConfig.Search.ParcelId) {
            searchArgs.push({
                name: 'parcel-ids',
                source: Beacon.SearchBox.createBloodhoundAdapter("parcelid", 5, "ParcelID", false),
                displayKey: 'ParcelID',
                templates: {
                    header: '<div class="tt-category">Parcel Numbers</div>',
                    suggestion: Beacon.Templates.searchbox_Parcelids,
                    empty: "none"
                }
            });
        }

        if (mapConfig.Search.AlternateId) {
            searchArgs.push({
                name: 'alternate-ids',
                source: Beacon.SearchBox.createBloodhoundAdapter('globalalternateid', 5, 'AlternateId', false),
                display: 'AlternateID', // the plugin will accept either 'display' or 'displayKey', but 'display' is used in documentation
                templates: {
                    header: '<div class="tt-category">Alternate Parcel Numbers</div>',
                    suggestion: Beacon.Templates.searchbox_Alternateids,
                    empty: "none"
                }
            });
        }

        var tt = ctrl.typeahead.apply(ctrl, searchArgs);

        // tweak the css to make it right-justified
        $(".tt-upm-global-search ~ .tt-dropdown-menu").css({
            left: "auto",
            right: "0"
        });

        tt.on("keydown", function (e) {
            switch (event.key) {
                case "Down": // IE/Edge specific value
                case "ArrowDown":
                case "Up": // IE/Edge specific value
                case "ArrowUp":
                case "Left": // IE/Edge specific value
                case "ArrowLeft":
                case "Right": // IE/Edge specific value
                case "ArrowRight":
                    e.stopPropagation();
                    break;
            }
        })


        tt.on("typeahead:cursorchanged", function (e, suggestion, searchType) {

            ctrl.attr('aria-labeledby', "Owner Name: " + suggestion.OwnerName + " Address:" + suggestion.Address + " Parcel ID:" + suggestion.ParcelID);


            switch (searchType) {
                case "owner-names":
                    ctrl.attr('aria-label', "Address: " + suggestion.Address + " Parcel ID:" + suggestion.ParcelID);
                    break;
                case "prop-addresses":
                    ctrl.attr('aria-label', "Owner:" + suggestion.OwnerName + " Parcel ID:" + suggestion.ParcelID);
                    break;
                case "parcel-ids":
                    ctrl.attr('aria-label', "Owner:" + suggestion.OwnerName + " Address:" + suggestion.Address);
                    break;

                default:
                    ctrl.attr('aria-label', "Owner Name: " + suggestion.OwnerName + " Addres:" + suggestion.Address + " Parcel ID:" + suggestion.ParcelID);

            }

        });
        // wire up selected item handler - for when a user clicks on one of the suggestions
        tt.on("typeahead:selected", function (e, suggestion, dataset) {

            ctrl.typeahead('val', '');

            const id = suggestion.SourceId ? suggestion.SourceId + '\267' + suggestion.ParcelID : suggestion.ParcelID;

            if (mapConfig.IsMapPage) { // If we are on a map page, then just call into the js that sets/zooms to the parcel by parcel id...AL
                //before we can do this, we need to make sure the active layer is the parcel layer (usually the default layer)

                if (Beacon.SearchBox.dMapZoom) // New Map
                    Beacon.SearchBox.dMapZoom(suggestion.ParcelID);

                if (Beacon.MapJS.setDefaultLayerToActive) // Old Map
                    Beacon.MapJS.setDefaultLayerToActive(function () {
                        Beacon.MapJS.selectionLayer.zoomAndSelectByKeyValue(suggestion.ParcelID)
                    });

            }
            else { // Otherwise, always target the default report...AL
                window.location = mapConfig.DefaultReportUrl.replace("{0}", id);
            }
        });
        tt.on("typeahead:noresults", function (e, missingHTMLElement) {
            var noResultsDiv = $(missingHTMLElement.header() );
            noResultsDiv.text(noResultsDiv.text() + " Has No Results");
            $("#noTypeAheadResults").html(noResultsDiv);
        });



    },

    initializeNameSearchBox: function () {
        // wire up other tt enabled search controls
        var sourceId = $(".tt-upm-name-search").attr('sourceid');
        var archiveTag = $(".tt-upm-name-search").attr('archiveTag');

        $(".tt-upm-name-search")
            .typeahead({
                minLength: 2,
                highlight: true,
                hint: false
            },
                {
                    name: 'owner-names',
                    source: Beacon.SearchBox.createBloodhoundAdapter2("Name", 10, "OwnerName", true, sourceId, archiveTag),
                    displayKey: 'OwnerName',
                    templates: {
                        header: '',
                        suggestion: '',
                        empty: "<div>No Owner Results</div>"
                    }
                })
            // wire up the on display item handler - for when the box gets displayed!


            .on("typeahead:updatehintcleared", function (e, suggestion, dataset) {

                $(".tt-upm-name-results-description").hide();

            })

            .on("typeahead:selected", function (e, suggestion, dataset) {
                $(".tt-upm-name-search-btn")[0].click();
            })
            .on("typeahead:noresults", function (e, missingHTMLElement) {
                $("#noTypeAheadResults").html(missingHTMLElement.empty());
            });

        $(".tt-upm-name-exact-search")
            .typeahead({
                minLength: 2,
                highlight: true,
                hint: false
            },
                {
                    name: 'owner-names',
                    source: Beacon.SearchBox.createBloodhoundAdapter2("NameExact", 10, "OwnerName", true, sourceId, archiveTag),
                    displayKey: 'OwnerName',
                    templates: {
                        header: '',
                        suggestion: '',
                        empty: "<div>No Owner Results</div>"
                    }
                })
            .on("typeahead:selected", function (e, suggestion, dataset) {
                $(".tt-upm-name-exact-search-btn")[0].click();
            })
            .on("typeahead:noresults", function (e, missingHTMLElement) {
                $("#noTypeAheadResults").html(missingHTMLElement.empty());
            });



    },

    initializeAddressSearchBox: function () {
        var sourceId = $(".tt-upm-address-search").attr('sourceid');
        var archiveTag = $(".tt-upm-name-search").attr('archiveTag');

        $(".tt-upm-address-search")
            .typeahead({
                minLength: 2,
                highlight: true,
                hint: false
            },
            {
                name: 'addresses',
                source: Beacon.SearchBox.createBloodhoundAdapter2("Address", 10, "Address", true, sourceId, archiveTag),
                displayKey: 'Address',
                templates: {
                    header: '',
                    suggestion: '',
                    empty: "<div>No Address Results</div>"
                }
            })
            .on("typeahead:selected", function (e, suggestion, dataset) {
                $(".tt-upm-address-search-btn")[0].click();
            })
            .on("typeahead:noresults", function (e, missingHTMLElement) {
                $("#noTypeAheadResults").html(missingHTMLElement.empty());
            });


        $(".tt-upm-address-exact-search")
            .typeahead({
                minLength: 2,
                highlight: true,
                hint: false
            },
            {
                name: 'addresses',
                source: Beacon.SearchBox.createBloodhoundAdapter2("AddressExact", 10, "Address", true, sourceId, archiveTag),
                displayKey: 'Address',
                templates: {
                    header: '',
                    suggestion: '',
                    empty: "<div>No Address Results</div>"
                }
            })
            .on("typeahead:selected", function (e, suggestion, dataset) {
                $(".tt-upm-address-exact-search-btn")[0].click();
            })
            .on("typeahead:noresults", function (e, missingHTMLElement) {
                $("#noTypeAheadResults").html(missingHTMLElement.empty());
            });

    },

    initializeStreetNameSearchBox: function () {
        var sourceId = $(".tt-upm-streetname-search").attr('sourceid');
        var archiveTag = $(".tt-upm-name-search").attr('archiveTag');



        $(".tt-upm-streetname-search")
            .typeahead({
                minLength: 2,
                highlight: true,
                hint: false
            },
            {
                name: 'streetnames',
                source: Beacon.SearchBox.createBloodhoundAdapter2("StreetName", 10, "StreetName", true, sourceId, archiveTag),
                displayKey: 'StreetName',
                templates: {
                    header: '',
                    suggestion: '',
                    empty: "<div>No Street Name Results</div>"
                }
            })
            .on("typeahead:selected", function (e, suggestion, dataset) {
                $(".tt-upm-streetname-search-btn")[0].click();
            })
            .on("typeahead:noresults", function (e, missingHTMLElement) {
                $("#noTypeAheadResults").html(missingHTMLElement.empty());
            });

    },

    initializeParcelIdSearchBox: function () {
        var sourceId = $(".tt-upm-parcelid-search").attr('sourceid');
        var archiveTag = $(".tt-upm-name-search").attr('archiveTag');


        $(".tt-upm-parcelid-search")
            .typeahead({
                minLength: 2,
                highlight: true,
                hint: false
            },
            {
                name: 'parcelids',
                source: Beacon.SearchBox.createBloodhoundAdapter2("ParcelId", 10, "ParcelID", false, sourceId, archiveTag),
                displayKey: 'ParcelID',
                templates: {
                    header: '',
                    suggestion: '',
                    empty: "<div>No ParcelID Results</div>"
                }
            })
            .on("typeahead:selected", function (e, suggestion, dataset) {
                $(".tt-upm-parcelid-search-btn")[0].click();
            })
            .on("typeahead:noresults", function (e, missingHTMLElement) {
                $("#noTypeAheadResults").html(missingHTMLElement.empty());
            });


    },

    initializeAlternateIdSearchBox: function () {
        var sourceId = $(".tt-upm-alternateid-search").attr('sourceid');
        var archiveTag = $(".tt-upm-name-search").attr('archiveTag');

        $(".tt-upm-alternateid-search")
            .typeahead({
                minLength: 2,
                highlight: true,
                hint: false
            },
            {
                name: 'alternateids',
                source: Beacon.SearchBox.createBloodhoundAdapter2("AlternateId", 10, "AlternateId", true, sourceId, archiveTag),
                displayKey: 'AlternateID',
                templates: {
                    header: '',
                    suggestion: '',
                    empty: "<div>No Alternate ID Results</div>"
                }
            })
            .on("typeahead:selected", function (e, suggestion, dataset) {
                $(".tt-upm-alternateid-search-btn")[0].click();
            })
            .on("typeahead:noresults", function (e, missingHTMLElement) {
                $("#noTypeAheadResults").html(missingHTMLElement.empty());
            });

    },

    initializeAlternateIdExactMatchSearchBox: function () {
        var sourceId = $(".tt-upm-alternateid-exactmatch-search").attr('sourceid');
        var archiveTag = $(".tt-upm-name-search").attr('archiveTag');


        $(".tt-upm-alternateid-exactmatch-search")
            .typeahead({
                minLength: 2,
                highlight: true,
                hint: false
            },
            {
                name: 'alternateidsexactmatch',
                source: Beacon.SearchBox.createBloodhoundAdapter2("AlternateIdExactMatch", 10, "AlternateIdExactMatch", true, sourceId, archiveTag),
                displayKey: 'AlternateID',
                templates: {
                    header: '',
                    suggestion: '',
                    empty: "<div>No AlternateID Results</div>"
                }
            })
            .on("typeahead:selected", function (e, suggestion, dataset) {
                $(".tt-upm-alternateid-exactmatch-search-btn")[0].click();
            })
            .on("typeahead:noresults", function (e, missingHTMLElement) {
                $("#noTypeAheadResults").html(missingHTMLElement.empty());
            });

    },

    initializeNeighborhoodSearchBox: function () {
        var sourceId = $(".tt-upm-neighborhood-search").attr('sourceid');
        var archiveTag = $(".tt-upm-name-search").attr('archiveTag');


        $(".tt-upm-neighborhood-search")
            .typeahead({
                minLength: 2,
                highlight: true,
                hint: false
            },
            {
                name: 'neighborhoods',
                source: Beacon.SearchBox.createBloodhoundAdapter2("Neighborhood", 10, "Neighborhood", true, sourceId, archiveTag),
                displayKey: 'Neighborhood',
                templates: {
                    header: '',
                    suggestion: '',
                    empty: "<div>No Neighborhood Results</div>"
                }
            })
            .on("typeahead:selected", function (e, suggestion, dataset) {
                $(".tt-upm-neighborhood-search-btn")[0].click();
            })
            .on("typeahead:noresults", function (e, missingHTMLElement) {
                $("#noTypeAheadResults").html(missingHTMLElement.empty());
            });

    },

    initializeBookSearchBox: function () {
        var sourceId = $(".tt-upm-book-search").attr('sourceid');
        var archiveTag = $(".tt-upm-name-search").attr('archiveTag');


        $(".tt-upm-book-search")
            .typeahead({
                minLength: 2,
                highlight: true,
                hint: false
            },
            {
                name: 'books',
                source: Beacon.SearchBox.createBloodhoundAdapter2("Book", 10, "Book", true, sourceId, archiveTag),
                displayKey: 'Book',
                templates: {
                    header: '',
                    suggestion: '',
                    empty: "<div>No Book Results</div>"
                }
            })
            .on("typeahead:selected", function (e, suggestion, dataset) {
                $(".tt-upm-book-search-btn")[0].click();
            })
            .on("typeahead:noresults", function (e, missingHTMLElement) {
                $("#noTypeAheadResults").html(missingHTMLElement.empty());
            });


    },

    initializePageSearchBox: function () {
        var sourceId = $(".tt-upm-page-search").attr('sourceid');
        var archiveTag = $(".tt-upm-name-search").attr('archiveTag');


        $(".tt-upm-page-search")
            .typeahead({
                minLength: 2,
                highlight: true,
                hint: false
            },
            {
                name: 'pages',
                source: Beacon.SearchBox.createBloodhoundAdapter2("Page", 10, "Page", true, sourceId, archiveTag),
                displayKey: 'Page',
                templates: {
                    header: '',
                    suggestion: '',
                    empty: "<div>No Page Results</div>"
                }
            })
            .on("typeahead:selected", function (e, suggestion, dataset) {
                $(".tt-upm-page-search-btn")[0].click();
            })            
            .on("typeahead:noresults", function (e, missingHTMLElement) {
                $("#noTypeAheadResults").html(missingHTMLElement.empty());
            });



    },

    initializeDBASearchBox: function () {
        var sourceId = $(".tt-upm-dba-search").attr('sourceid');
        var archiveTag = $(".tt-upm-name-search").attr('archiveTag');


        $(".tt-upm-dba-search")
            .typeahead({
                minLength: 2,
                highlight: true,
                hint: false
            },
            {
                name: 'dbas',
                source: Beacon.SearchBox.createBloodhoundAdapter2("DBA", 10, "DBA", true, sourceId, archiveTag),
                displayKey: 'DBA',
                templates: {
                    header: '',
                    suggestion: '',
                    empty: "<div>No DBA Results</div>"
                }
            })
            .on("typeahead:selected", function (e, suggestion, dataset) {
                $(".tt-upm-dba-search-btn")[0].click();
            })
            .on("typeahead:noresults", function (e, missingHTMLElement) {
                $("#noTypeAheadResults").html(missingHTMLElement.empty());
            });

    },

    // this is for the global search box:
    createBloodhoundAdapter: function (mode, maxResults, tokenizerFieldName, useNonword) {

        var tokenizer = useNonword ? Bloodhound.tokenizers.nonword : Bloodhound.tokenizers.whitespace;

        var bh = new Bloodhound({
            name: 'bh' + mode,
            limit: maxResults,
            remote: {
                url: "/api/UpmTypeahead/UpmTypeahead?QPS=" + mapConfig.QPS + "&mode=" + mode + "&text=%QUERY",
                rateLimitBy: 'throttle',  // debounce || throttle
                rateLimitWait: 300
            },
            datumTokenizer: function (d) {
                return tokenizer(d[tokenizerFieldName]);
            },
            queryTokenizer: tokenizer
        });
        bh.initialize();
        return bh.ttAdapter();
    },

    // this is for the typeahead dropdowns in upm search
    createBloodhoundAdapter2: function (mode, maxResults, tokenizerFieldName, useNonword, sourceId, archiveTag) {

        if (archiveTag == null) {
            archiveTag = "";
        }

        var url = "/api/UpmTypeahead/" + mode + "?QPS=" + mapConfig.QPS + "&text=%QUERY&sourceId=" + sourceId + "&archiveTag=" + archiveTag;
        var tokenizer = useNonword ? Bloodhound.tokenizers.nonword : Bloodhound.tokenizers.whitespace;

        var bh = new Bloodhound({
            name: 'bh' + mode,
            limit: maxResults,
            remote: {
                url: url,
                rateLimitBy: 'throttle',  // debounce || throttle
                rateLimitWait: 300
            },
            datumTokenizer: function (d) {
                return tokenizer(d[tokenizerFieldName]);
            },
            queryTokenizer: tokenizer
        });
        bh.initialize();
        return bh.ttAdapter();
    },


    initSearchControl: function (prefetch, url, txtElementId, btnElementId, moduleId, cacheThumbprint) {

        $("#" + txtElementId)
            .on("typeahead:selected", function (e, suggestion, dataset) {
                $("#" + btnElementId)[0].click();
            });

    },

    // for GEN1_AutoCompleteSearch module:

    initTypeaheadControl: function (prefetch, url, txtElementId, btnElementId, moduleId, cacheThumbprint) {

        var bhOptions = {
            name: 'bloodhound-' + moduleId,
            limit: 10,
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
            queryTokenizer: Bloodhound.tokenizers.whitespace
        };

        if (prefetch) {
            bhOptions.prefetch = {
                url: url,
                cacheKey: 'AutocompleteCache-' + moduleId,
                ttl: 8 * 60 * 60 * 1000, // 8 hours in ms
                thumbprint: cacheThumbprint,
                filter: function (list) {
                    return $.map(list, function (d) { return { 'value': d }; });
                }
            };
        } else {
            bhOptions.remote = {
                url: url + '&text=%QUERY',
                rateLimitBy: 'throttle',  // debounce || throttle
                rateLimitWait: 300,
                filter: function (list) {
                    return $.map(list, function (d) { return { 'value': d }; });
                }
            };
        }

        var bh = new Bloodhound(bhOptions);
        bh.storage = null; // override localstorage caching - until we figure out how to limit/monitor how much gets stored there
        bh.initialize();


        $("#" + txtElementId)
            .typeahead({
                minLength: 1,
                highlight: true,
                hint: false
            },
            {
                name: 'typeahead-' + moduleId,
                source: bh.ttAdapter(),
                displayKey: 'value'
            })
            .on("typeahead:selected", function (e, suggestion, dataset) {
                $("#" + btnElementId)[0].click();
            })            
            .on("typeahead:noresults", function (e, missingHTMLElement) {
                $("#noTypeAheadResults").html(missingHTMLElement.empty());
            });

    },

    // this supports azure-based images, since we can't 
    imageLoadError: function (el) {
        return Beacon.LazyLoader.swapImageOnFailure(el);
        //el.onerror = null;
        //el.src = Beacon.LazyLoader.missingUrl;
        //return null;
    },

    //initSearchIntentHandler: function () {

    //    $("a[SearchIntent],button[SearchIntent],input[SearchIntent]").click(function (el) {
    //        Beacon.API.SetResults(mapConfig.LayerId, this.getAttribute("SearchIntent"));
    //    });

    //},

    initSearchIntentHandler: function () {
        Beacon.sessionStorage.removeItem("SearchIntent");
        $("a[SearchIntent],button[SearchIntent],input[SearchIntent]").click(function (el) {
            Beacon.sessionStorage["SearchIntent"] = this.getAttribute("SearchIntent");
        });
    },

    getSearchIntent: function () {
        return Beacon.sessionStorage["SearchIntent"];
    },

    CLASS_NAME: 'Beacon.SearchBox'
};
